<template>
  <div class="app" :class="$store.state.darkTheme && 'app--theme-black'">
    <Loader v-if="$store.state.loader"/>
    <div class="notifications">
      <div
          class="notification"
          :class="notification.state"
          v-for="notification in $store.state.notifications"
          :key="notification.id"
      >
        {{ notification.text }}
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Loader from '@/components/LoaderComponent.vue'

export default {
  name: 'App',
  components: {
    Loader,
  },
  data() {
    return {
      tg: null,
    }
  },
  mounted() {
    console.log(window.Telegram.WebApp);
    if (!window.Telegram.WebApp) {
      this.$router.push({name: 'notTgPage'})
    }
    this.tg = window.Telegram.WebApp
    this.$store.dispatch('setUser', {id: this.tg?.initDataUnsafe?.user?.id || null})
    this.$store.dispatch('setDarkTheme', this.tg?.colorScheme === 'dark')
    this.$store.dispatch('setApi', process.env.VUE_APP_API || '')
  },
  watch: {
    '$store.state.closeApp'() {
      console.log('close');
      this.tg.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.app {
  padding: 8px;
  min-height: 100vh;
  background-color: white;

  &--theme-black {
    background-color: var(--tg-theme-bg-color);
    color: white;
  }
}

.notifications {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 500px;
  z-index: 100;
}

.notification {
  margin: 20px 0;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  padding: 10px;
  width: 100%;
  border: 1px solid black;

  &.error {
    border-color: #ea4c4c;
    background-color: #e78888;
  }

  &.warning {
    border-color: #db7617;
    background-color: #eb9349;
  }

  &.success {
    border-color: #38e519;
    background-color: #8fe180;
  }
}
</style>

<style lang="scss">
.page {
  max-width: 500px;
  margin: auto;
  font-size: 16px;
  font-family: 'Roboto';
}
</style>
