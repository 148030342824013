<template>
	<div>
		<InputCode
			:error="!!error.length"
			@input="sendCode($event)"
		/>
		<div class="error" v-if="error">
			{{ error }}
		</div>
		<div class="message" :class="$store.state.darkTheme && 'message--theme-black'" v-else>
			<template v-if="time">
				Получить новый код можно через 00:{{ time }}
			</template>
			<Button
				v-else
				text="Отправить код повторно"
				secondary
				@click="repeatSendCode()"
			/>
		</div>
	</div>
</template>

<script>
import InputCode from '@/components/InputCode'
import Button from '@/components/ButtonComponent'
import axios from 'axios'

export default {
	name: 'CodeSlide',
	components: {
		InputCode, Button
	},
	props: {
		typePage: {type: String, required: true}
	},
	data() {
		return {
			error: '',
			time: 0,
			timer: null
		}
	},
	mounted() {
		this.startTimerCode()
	},
	methods: {
		startTimerCode() {
			this.time = 59
			this.timer = setInterval(() => {
				this.time--
				if (this.time === 0) {
					this.time = null
					clearInterval(this.timer)
				} 
			}, 1000);
		},	
		repeatSendCode() {
			this.$store.dispatch('setLoader', true)
			axios({
				method: "post",
				url: this.$store.state.api.url + '/phone',
				data: { 
					phone: this.$store.state.user.phone,
					user_id: this.$store.state.user.id
				}
			})
			.then(response => {
				console.log(response);
				if (response.data.status) {
					this.$store.dispatch('setLoader', false)
					this.startTimerCode()		
				} else {
					this.$store.dispatch('setLoader', false)
					this.$store.dispatch('setNotifiction', { state: 'error', text: 'К сожалению, произошла ошибка. Попробуйте позже'})
				}
			})
			.catch(error => {
				console.log(error);
				this.$store.dispatch('setLoader', false)
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'К сожалению, произошла ошибка. Попробуйте позже'})
			})
        },
		sendCode(code) {
			this.$store.dispatch('setLoader', true)
			axios({
				method: "post",
				url: this.$store.state.api.url + '/code',
				data: { 
					code: code,
					user_id: this.$store.state.user.id
				}
			})
			.then(response => {
				console.log(response);
				if (response.data.status) {
					this.$emit('successSlide')
					this.$store.dispatch('setLoader', false)		
				} else {
					if (response.data.message === 'not_user') {
						this.$emit('registration')
						this.$store.dispatch('setLoader', false)
						if (this.typePage === 'login') {
							this.$store.dispatch('setNotifiction', { state: 'warning', text: 'Вы не зарегистрированы, поэтому мы предлагаем зарегистрироваться сейчас.'})
						}
					} else {
						this.$store.dispatch('setLoader', false)
						this.error = 'К сожалению, вы ввели неправильный код. Попробуйте ввести его еще раз'
					}
				}
			})
			.catch(error => {
				console.log(error);
				this.$store.dispatch('setLoader', false)
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'К сожалению, произошла ошибка. Попробуйте позже'})
			})
		}
	}
}
</script>

<style lang="scss" scoped>
	.message {
		margin-top: 20px;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: rgba(0, 0, 0, 0.45);

		&--theme-black {
			color: white;
		}
	}
	.error {
		margin-top: 20px;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: #EB6161;
	}
</style>