<template>
	<div class="registration">
		<InputPhone
			:value="$store.state.user.phone.substring(1)"
			disabled
		/>
		<Input
			label="Ваше имя*"
			placeholder="Имя"
			:error="user.firstName && !user.firstName.length"
			@input="user.firstName=$event"
		/>
		<Input
			label="Ваша фамилия"
			placeholder="Фамилия"
			@input="user.secondName=$event"
		/>
		<div class="registration__gender">
			<Checkbox
				label="Мужчина"
				id="gender_man"
				:value="user.gender==='M'"
				:error="user.gender && !user.gender.length"
				@change="changeCheckbox('M', $event)"
			/>
			<Checkbox
				label="Женщина"
				id="gender_woman"
				:value="user.gender==='F'"
				:error="user.gender && !user.gender.length"
				@change="changeCheckbox('F', $event)"
			/>
		</div>
		<Input
			label="Почта*"
			placeholder="mail@mail.ru"
			:error="user.mail && !user.mail.length"
			@input="user.mail=$event"
		/>
		<Input
			label="Пароль*"
			placeholder="*****"
			type="password"
			:error="user.password && user.password.length < 6"
			@input="user.password=$event"
		/>
		<Input
			label="Подтверждение пароля*"
			placeholder="*****"
			type="password"
			:error="user.passwordRepeat && user.passwordRepeat.length < 6"
			@input="user.passwordRepeat=$event"
		/>
		<Button
			@click="registration()"
			text="Зарегистрироваться"
		/>
	</div>
</template>

<script>
import Button from '@/components/ButtonComponent'
import Input from '@/components/InputComponent'
import Checkbox from '@/components/CheckboxComponent'
import InputPhone from '@/components/InputPhone'
import axios from 'axios'

export default {
	name: 'RegistrationSlide',
	components: {
		Button, Input, Checkbox, InputPhone,
	},
	data() {
		return {
			user: {
				firstName: null,
				secondName: null,
				mail: null,
				gender: null,
				password: null,
				passwordRepeat: null
			}
		}
	},
  mounted() {
    if(!this.$store.state.isClickReg){
      this.$store.dispatch('setClicks', 'reg')
    }
  },
  methods: {
		registration() {
			if (!this.user.firstName || !this.user.firstName.length) {
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'Введите корректное имя'})
				this.user.firstName = ''
				return
			}
			if (!this.user.secondName || !this.user.secondName.length) {
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'Введите корректную фамилию'})
				this.user.secondName = ''
				return
			}
			if (!this.user.gender || !this.user.gender.length) {
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'Выберите пол'})
				this.user.gender = ''
				return
			}
			// eslint-disable-next-line
			const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
			if (!this.user.mail || !this.user.mail.length || !reg.test(this.user.mail)) {
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'Введите корректный e-mail'})
				this.user.mail = ''
				return
			}
			if (!this.user.password || !this.user.passwordRepeat || this.user.password.length < 6 ||
				this.user.passwordRepeat.length < 6) {
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'Длина паролей должна быть больше 6 символов'})
				return
			}
			if (this.user.password !== this.user.passwordRepeat) {
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'Ваши пароли не совпадают'})
				return
			}
			this.$store.dispatch('setLoader', true)
			axios({
				method: "post",
				url: this.$store.state.api.url + '/register',
				data: {
					phone: this.$store.state.user.phone,
					email: this.user.mail,
					name: this.user.firstName,
					last_name: this.user.secondName,
					gender: this.user.gender,
					password: this.user.password,
					user_id: this.$store.state.user.id
				}
			})
			.then(response => {
				console.log(response);
				if (response.data.status) {
					this.$emit('success')
					this.$store.dispatch('setLoader', false)
				} else {
					if (response.data.message === 'already_register') {
						this.$store.dispatch('setLoader', false)
						this.$emit('haveAccount')
						this.$store.dispatch('setNotifiction', { state: 'warning', text: 'Вы уже зарегистрированы, поэтому мы авторизовали вас.'})
					} else if (response.data.message === 'no_correct_email') {
						this.$store.dispatch('setLoader', false)
						this.$store.dispatch('setNotifiction', { state: 'error', text: 'Введите корректный e-mail'})
					} else {
						this.$store.dispatch('setLoader', false)
						this.$store.dispatch('setNotifiction', { state: 'error', text: 'К сожалению, произошла ошибка. Попробуйте позже'})
					}
				}
			})
			.catch(error => {
				console.log(error);
				this.$store.dispatch('setLoader', false)
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'К сожалению, произошла ошибка. Попробуйте позже'})
			})
		},
		changeCheckbox(field, state) {
			if (state) {
				this.user.gender = field
			} else if (this.user.gender === field){
				this.user.gender = ''
			}
		}
	}
}
</script>

<style lang="scss" scoped>
	.registration {
		display: flex;
		flex-direction: column;
		gap: 20px;

		@media (max-width: 500px) {
			padding-bottom: 300px;
		}
	}

	.registration__gender {
		display: grid;
		grid-template-columns: repeat(2, calc((100% - 20px) / 2));
		gap: 20px;
	}
</style>
