<template>
  <div class="page page-check">
    <MainSlide
        v-if="openSlide === 'main'"
        @openSuccessSlide="openSlide = 'success'"
        @openFormSlide="openSlide = 'form'"
        @openScannerSlide="openSlide = 'scanner'"
    />
    <FormSlide
        v-if="openSlide === 'form'"
        @openSuccessSlide="openSlide = 'success'"
        @openMainSlide="openSlide = 'main'"
    />
    <ScannerSlide
        v-if="openSlide === 'scanner'"
        @openSuccessSlide="openSlide = 'success'"
        @openMainSlide="openSlide = 'main'"
    />
    <SuccessSlide
        v-if="openSlide === 'success'"
        @openMainSlide="openSlide = 'main'"
    />
  </div>
</template>

<script>
import MainSlide from './MainSlide'
import FormSlide from './FormSlide'
import SuccessSlide from './SuccessSlide'
import ScannerSlide from './ScannerSlide'

export default {
  name: 'CheckPage',
  components: {
    MainSlide, FormSlide, SuccessSlide, ScannerSlide
  },
  data() {
    return {
      openSlide: 'main'
    }
  },
  mounted() {
    if (!this.$store.state.isClickReceipt) {
      this.$store.dispatch('setClicks', 'receipt_download')
    }
  },
}
</script>

<style lang="scss" scoped>
.page-check {
  font-size: 15px;
}
</style>
