<template>
	<div>
		<div class="success__title">Спасибо!</div>
		<div 
			class="success__text" 
			v-if="stateSlide === 'login'"
		>
			Спасибо! 🙂
		</div>
		<div 
			class="success__text" 
			v-if="stateSlide === 'register'"
		>
			Вы успешно зарегистрировались в Клубе привелений «МоиГлаза»!
		</div>
		
		<div class="success__text">
			Все получилось, теперь мы можем общаться с вами без всяких ограничений — просто выберете нужный раздел в меню.
		</div>
		<div class="success__text">
			🎁 Дарю вам стикерпак от Alcon! Сохраните его через кнопку «Стикерпак» в меню бота.
		</div>
		<Button
			text="Перейти к боту"
			@click="$store.dispatch('closeApp')"
		/>
		<Button
			text="Загрузить чек"
			secondary
			@click="$router.push({name: 'Check'})"
			class="button-check"
		/>
	</div>
</template>

<script>
import Button from '@/components/ButtonComponent'

export default {
	name: 'SuccessSlide',
	components: {
		Button
	},
	props: {
		stateSlide: {type: String, required: true}
	}
}
</script>

<style lang="scss" scoped>
	.success__title {
		color: #50A7EA;
		font-weight: 500;
		font-size: 20px;
		line-height: 18px;
		margin-bottom: 14px;
	}
	.success__text {
		font-weight: 400;
		font-size: 15px;
		line-height: 18px;
		margin-bottom: 16px;
	}
	.button-check {
		margin-top: 20px;
	}
</style>