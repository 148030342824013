<template>
	<div>
		<label class="label">Введите код</label>
		<div class="pincode">
			<input
				class="input"
				:class="error && 'error'"
				v-model="pin1"
				v-mask="'#'"
				ref="pin1"
				type="number"
			>
			<input
				class="input"
				:class="error && 'error'"
				v-model="pin2"
				v-mask="'#'"
				ref="pin2"
				type="number"
				@keyup.delete="!pin2 && clear('pin1')"
			>
			<input
				class="input"
				:class="error && 'error'"
				v-model="pin3"
				v-mask="'#'"
				ref="pin3"
				type="number"
				@keyup.delete="!pin3 && clear('pin2')"
			>
			<input
				class="input"
				:class="error && 'error'"
				v-model="pin4"
				v-mask="'#'"
				ref="pin4"
				type="number"
				@keyup.delete="!pin4 && clear('pin3')"
			>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InputPhone',
	props: {
		error: {type: Boolean, default: false},
	},
	data() {
		return {
			pin1: '',
			pin2: '',
			pin3: '',
			pin4: '',
		}
	},
	methods: {
		clear(pin) {
			this[pin] = null
			this.changeSelect()
		},
		changeSelect() {
			if (!this.pin1) {
				this.$refs.pin1.focus()
			} else if (!this.pin2) {
				this.$refs.pin2.focus()
			} else if (!this.pin3) {
				this.$refs.pin3.focus()
			} else {
				this.$refs.pin4.focus()
			}
		}
	},
	watch: {
		pin1() {
			this.pin2 = null
			this.pin3 = null
			this.pin4 = null
			this.changeSelect()
		},
		pin2() {
			if (this.pin1) {
				this.pin3 = null
				this.pin4 = null
			} else {
				this.pin1 = this.pin2
				this.pin2 = null
			}
			this.changeSelect()
		},
		pin3() {
			if (this.pin2) {
				this.pin4 = null
			} else {
				this.pin2 = this.pin3
				this.pin3 = null
			}
			this.changeSelect()
		},
		pin4() {
			if (this.pin3) {
				this.$emit('input', `${this.pin1}${this.pin2}${this.pin3}${this.pin4}`)
			} else {
				this.pin3 = this.pin4
				this.pin4 = null
			}
		},
	},
	mounted() {
		this.$refs.pin1.focus()
	}
}
</script>

<style lang="scss" scoped>
	.pincode {
		display: grid;
		grid-template-columns: repeat(4, 40px);
		gap: 20px;
	}
	.label {
		font-weight: 400;
		font-size: 15px;
		line-height: 23px;
	}
	.input {
		border: 1px solid #D9D9D9;
		border-radius: 5px;
		padding: 0 12px;
		height: 40px;
		font-family: 'Roboto';
		font-size: 14px;
		line-height: 18px;
		outline: none;
		width: 100%;
		outline: none;
		box-sizing: border-box;

		&:focus {
			border-color: #8b8b8b;
		}

		/* Chrome, Safari, Edge, Opera */
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		&[type=number] {
			-moz-appearance: textfield;
		}
	}
	.error {
		border-color: #EB6161;
	}
</style>