import Vue from 'vue'
import Router from 'vue-router'
import AuthorizationPage from '@/pages/AuthorizationPage'
import CheckPage from '@/pages/CheckPage'
import NotTgPage from '@/pages/NotTgPage'

Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [
		{
			path: '/login',
			name: 'Authorization',
			component: AuthorizationPage,
		},
		{
			path: '/check',
			name: 'Check',
			component: CheckPage,
		},
		{
			path: '/not-tg',
			name: 'NotTgPage',
			component: NotTgPage,
		},
		{
			path: '*',
			redirect: { name: 'Authorization' },
		},
	]
})
