<template>
	<div>
		<input 
			type="checkbox" 
			class="input" 
			:id="id" 
			v-model="state"
		>
		<label 
			class="label" 
			:for="id" 
			:class="error && 'error'"
		>
			{{ label }}
		</label>
	</div>
</template>

<script>
export default {
	name: 'CheckboxComponent',
	props: {
		label: {type: String, default: ''},
		id: {type: String, required: true},
		value: {type: Boolean, required: true},
		error: {type: Boolean, default: false},
	},
	data() {
		return {
			state: false
		}
	},
	mounted() {
		this.state = this.value
	},
	watch: {
		value() {
			this.state = this.value
		},
		state() {
			this.$emit('change', this.state)
		}
	}
}
</script>

<style lang="scss" scoped>
	.input {
		padding: 0;
		height: initial;
		width: initial;
		margin-bottom: 0;
		display: none;
		cursor: pointer;	
	}
	.label {
		position: relative;
		cursor: pointer;
		font-weight: 400;
		font-size: 15px;
		line-height: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		width: max-content;
	}
	.error {
		color: #EB6161;
	}
	.label:before {
		content:'';
		-webkit-appearance: none;
		background-color: transparent;
		border: 1px solid #50A7EA;
		border-radius: 3px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
		padding: 7px;
		position: relative;
		cursor: pointer;
		margin-right: 5px;
	}
	.input:checked + .label:after {
		content: '';
		display: block;
		position: absolute;
		top: 3px;
		left: 5px;
		width: 4px;
		height: 10px;
		border: solid #50A7EA;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
	}
</style>