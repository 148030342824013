<template>
  <div>
    <div class="title1">Загрузка чека</div>
    <div v-if="$store.state.uploadStatus === 'ok'">
      <div class="text">
        Если вы купили линзы или растворы Alcon менее 30 дней назад,
        то вы можете зарегистрировать чек об их покупке, чтобы
        получить до 1400 бонусных баллов.
      </div>
      <div class="text title2">Обратите внимание!</div>
      <div class="text">
        1. На фотографии чека должен четко читаться QR-код
      </div>
      <div class="text">
        2. Файл должен быть в формате JPEG или PNG, размером не
        больше 20MB
      </div>
      <div class="text">
        3. Вы можете отсканировать и\или сфотографировать не более 2
        (двух) чеков в день, и не более 4 (четырех) в месяц.
      </div>
      <div class="buttons">
        <Button
            text="Сканировать QR-код"
            secondary
            @click="$emit('openScannerSlide')"
        />
        <Button
            text="Загрузить файл"
            secondary
            @click="$refs.uploadFile.click()"
        />
        <Button
            text="Отправить данные из чека"
            secondary
            @click="$emit('openFormSlide')"
        />
        <input type="file" hidden ref="uploadFile" @change="uploadFile">
      </div>
    </div>
    <div v-else-if="$store.state.uploadStatus ==='two_day'">
      <div class="text">
        Вы уже загрузили два чека за сегодняший день. По Правилам Клуба привилегий, пользователь может загружать не
        более двух чеков в день и не более четырех в месяц.
      </div>
    </div>
    <div v-else>
      <div class="text">
        Вы уже загрузили четыре чека в этом месяце. По Правилам Клуба привилегий, пользователь может загружать не более
        двух чеков в день и не более четырех в месяц.
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ButtonComponent'
import axios from 'axios'
import store from "@/store";

export default {
  name: 'MainSlide',
  components: {
    Button,
  },
  methods: {
    async uploadFile(event) {
      if (!this.$store.state.isClickQr) {
        this.$store.dispatch('setClicks', 'receipt_qr')
      }
      this.$store.dispatch('setLoader', true)
      let data = new FormData();
      data.append('file', event.target.files[0])
      data.append('user_id', this.$store.state.user.id)
      data.append('platform', 'tg')
      await axios({
        method: "post",
        url: this.$store.state.api.url + '/photo',
        data: data,
        headers: {"Content-Type": "multipart/form-data"},
      })
          .then((response) => {
            if (response.data.status) {
              this.$store.dispatch('setLoader', false)
              this.$emit('openSuccessSlide')
            } else if (response.data.message === 'not_file') {
              this.$store.dispatch('setLoader', false)
              this.$store.dispatch('setNotifiction', {
                state: 'error',
                text: 'К сожалению, файл не подходит. Попробуйте другой'
              })
            } else {
              this.$store.dispatch('setLoader', false)
              this.$store.dispatch('setNotifiction', {state: 'error', text: response.data.message})
            }
          })
          .catch(error => {
            console.log(error);
            this.$store.dispatch('setLoader', false)
            this.$store.dispatch('setNotifiction', {
              state: 'error',
              text: 'К сожалению, произошла ошибка. Попробуйте позже'
            })
            this.$store.dispatch('setNotifiction', {state: 'error', text: error})
          });

      const formData = new FormData();
      formData.append('user_id', store.state.user.id);

      await axios({
        method: "post",
        url: store.state.api.url + '/check_receipts',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(({data}) => {
        store.dispatch('setUploadStatus', data.message);
      }).catch(error => {
        console.log(error);
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.title1 {
  font-weight: 600;
  color: #50A7EA;
  font-size: 20px;
  line-height: 18px;
}

.title2 {
  font-weight: 600;
}

.text {
  margin: 10px 0;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
