import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import App from '@/App.vue'
import VueMask from 'v-mask'
import axios from 'axios'

Vue.use(VueMask);

Vue.config.productionTip = false

router.beforeEach(async (to, from, next) => {
    if (store.state.firstLoad) {
        await store.dispatch('setLoader', true)
        // setTimeout чтобы подгрузился id из tg и попал в стор

        await axios({
            method: "post",
            url: store.state.api.url + '/auth',
            data: {
                user_id: store.state.user.id
            }
        }).then(response => {
            if (response.data.auth) {
                store.dispatch('setUser', {
                    id: store.state.user.id,
                    phone: response.data.phone
                })
                store.dispatch('setLoader', false)
                store.dispatch('setFirstLoad', false)
                next()
            } else {
                router.push({name: 'Authorization'})
                store.dispatch('setLoader', false)
                store.dispatch('setFirstLoad', false)
            }
        }).catch(error => {
            console.log(error);
            store.dispatch('setLoader', false)
            router.push({name: 'Authorization'})
            store.dispatch('setNotifiction', {
                state: 'error',
                text: 'К сожалению, произошла ошибка. Попробуйте позже'
            })
        })

        const formData = new FormData();
        formData.append('user_id', store.state.user.id);

        await axios({
            method: "post",
            url: store.state.api.url + '/check_receipts',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(({data}) => {
            store.dispatch('setUploadStatus', data.message);
        }).catch(error => {
            console.log(error);
        })

    } else {
        next()
    }
})

const app = new Vue({
    render: h => h(App),
    router,
    store,
})

app.$mount('#app')
