<template>
	<div>
		<label class="label">Номер телефона</label>
		<input
			class="input"
			v-model="phone"
			:class="error && 'error'"
			placeholder="+7 (***) *** ** **"
			v-mask="'+7 (###) ### ## ##'"
			ref="inputPhone"
			:disabled="disabled"
		>
	</div>
</template>

<script>
export default {
	name: 'InputPhone',
	props: {
		error: {type: Boolean, default: false},
		disabled: {type: Boolean, default: false},
		value: {type: String, default: ''},
	},
	data() {
		return {
			phone: ''
		}
	},
	watch: {
		phone() {
			if (this.phone.length === 1) {
				if ((this.phone === '8') || (this.phone === '7') || (this.phone === '+')) {
					this.phone = ''
				}
			} else if (String(this.phone.replace(/\D+/g, '')).length === 11) {
				this.$emit('input', String(this.phone.replace(/\D+/g, '')))
			}
		},
		value() {
			this.phone = this.value
		}
	},
	mounted() {
		this.$refs.inputPhone.focus()
		this.phone = this.value
	}
}
</script>

<style lang="scss" scoped>
	.input {
		border: 1px solid #D9D9D9;
		border-radius: 5px;
		padding: 0 12px;
		height: 40px;
		font-family: 'Roboto';
		font-size: 14px;
		line-height: 18px;
		outline: none;
		width: 100%;
		outline: none;
		box-sizing: border-box;

		&:focus {
			border-color: #8b8b8b;
		}
	}
	.label {
		font-weight: 400;
		font-size: 15px;
		line-height: 23px;
	}
	.error {
		border-color: #EB6161;
	}
</style>