<template>
  <div>
    <QrcodeStream @decode="onDecode"/>
    <Button @click="$emit('openMainSlide')" secondary text="Назад"/>
  </div>
</template>

<script>
import Button from '@/components/ButtonComponent'
import {QrcodeStream} from 'vue-qrcode-reader'
import axios from 'axios'
import store from "@/store";

export default {
  name: 'ScannerSlide',
  components: {
    QrcodeStream, Button,
  },
  data() {
    return {
      receipt: {
        m: "",
        qr: '',
        fn: '',
        fd: '',
        fpd: '',
        dt: '',
        tm: '',
        amount: '',
        type: '',
      }
    }
  },
  mounted() {
    if (!this.$store.state.isClickScan) {
      this.$store.dispatch('setClicks', 'receipt_scan')
    }
  },
  methods: {
    onDecode(qr_data) {
      this.$store.dispatch('setNotifiction', {state: 'success', text: 'чек распознан: начат анализ'})
      try {
        this.$store.dispatch('setLoader', true)
        this.receipt.qr = qr_data;

        if (!qr_data) {
          this.$store.dispatch('setNotifiction', {
            state: 'error',
            text: 'К сожалению, произошла ошибка. Попробуйте еще раз'
          })
          return
        }

        let obj = {};

        // разделяем параметры
        let arr = qr_data.split('&');

        for (let i = 0; i < arr.length; i++) {
          // разделяем параметр на ключ => значение
          let a = arr[i].split('=');

          let paramNum = undefined;
          let paramName = a[0].replace(/\[\d*\]/, function (v) {
            paramNum = v.slice(1, -1);
            return '';
          });

          // передача значения параметра ('true' если значение не задано)
          let paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

          // преобразование регистра
          paramName = paramName.toLowerCase();
          paramValue = paramValue.toLowerCase();

          // если ключ параметра уже задан
          if (obj[paramName]) {
            // преобразуем текущее значение в массив
            if (typeof obj[paramName] === 'string') {
              obj[paramName] = [obj[paramName]];
            }
            // если не задан индекс...
            if (typeof paramNum === 'undefined') {
              // помещаем значение в конец массива
              obj[paramName].push(paramValue);
            }
            // если индекс задан...
            else {
              // размещаем элемент по заданному индексу
              obj[paramName][paramNum] = paramValue;
            }
          }
          // если параметр не задан, делаем это вручную
          else {
            obj[paramName] = paramValue;
          }
        }

        let error = 0

        if (obj.fn) {
          this.receipt.fn = obj.fn;
        } else {
          error = 1;
        }

        if (obj.t) {
          this.receipt.tm = obj.t;
        } else {
          error = 1;
        }


        if (obj.i) {
          this.receipt.fd = obj.i;
        } else {
          error = 1;
        }

        if (obj.s) {
          this.receipt.amount = obj.s;
        } else {
          error = 1;
        }

        if (obj.fp) {
          this.receipt.fpd = obj.fp;
        } else {
          error = 1;
        }

        if (error > 0) {
          this.receipt = {m: "", fn: "", fd: "", fpd: "", dt: "", tm: "", amount: "", qr: "", type: "input"}
          this.$store.dispatch('setNotifiction', {
            state: 'error',
            text: 'К сожалению, qr-код не сканируется. Попробуйте позже'
          })
        } else {
          this.receipt.type = "qr";
          axios({
            method: "post",
            url: this.$store.state.api.url + '/send_qr',
            data: {
              message: "",
              receipt: this.receipt,
              platform: 'tg',
              user_id: this.$store.state.user.id,
              fbapp: navigator.userAgent.match(/MessengerLiteForiOS/i) ? 1 : 0,
              ua: navigator.userAgent
            }
          })
              .then((response) => {
                if (response.data.status) {
                  this.$store.dispatch('setLoader', false)

                  if (response.data['hide_button']) {
                    store.dispatch('setUploadStatus', 'two_day');
                  }

                  this.$emit('openSuccessSlide');
                } else {
                  this.$store.dispatch('setLoader', false)
                  this.$store.dispatch('setNotifiction', {state: 'error', text: response.data.message})
                }
              })
              .catch(error => {
                console.log(error);
                this.$store.dispatch('setLoader', false)
                this.$store.dispatch('setNotifiction', {
                  state: 'error',
                  text: 'К сожалению, произошла ошибка. Попробуйте позже'
                })
                this.$store.dispatch('setNotifiction', {state: 'error', text: error})
              })
        }
      } catch (error) {
        console.log(error);
        this.receipt = {
          m: "",
          qr: '',
          fn: '',
          fd: '',
          fpd: '',
          dt: '',
          tm: '',
          amount: '',
          type: '',
        }
        this.$store.dispatch('setLoader', false)
        this.$store.dispatch('setNotifiction', {
          state: 'error',
          text: 'К сожалению, произошла ошибка. Попробуйте позже'
        })
      }
    }
  }
}
</script>
